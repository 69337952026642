import React from 'react';
import { Field } from 'formik';
import classnames from "classnames";
import "./forms.scss";

const CheckBox = (props) => {
  const { label, name, options, required, ...rest } = props;

  const ifRequired = ()=>{
    if(required == true){
      return <span className="required">*</span>
    }
  }

  return (
    <Field name={name} {...rest} >
      {
        ({ field, meta }) => (
          <div className={classnames("partie-form__field-container", {
            'partie-form__field-container--error': meta.error && meta.touched
          })}>
            <label className="partie-form__label" htmlFor={name}>{label} {ifRequired()}</label>
            <div className="partie-form__field">
              {options.map(option => {
                return (
                  <React.Fragment key={option.key}>
                    <div className="partie-form__choice-container">
                      <input
                        type='checkbox'
                        id={option.id}
                        {...field}
                        value={option.value}
                      />
                      <label className="partie-form__select" htmlFor={option.id}>{option.key}</label>
                    </div>
                  </React.Fragment>
                );
              })
              }
            </div>
            {(meta.error && meta.touched) && (
              <span className="partie-form__field-caption">{meta.error}</span>
            )}
          </div>
        )}
    </Field>
  );
};

export default CheckBox;